import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './shared/containers/page-not-found/page-not-found.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import 'hammerjs';
import * as moment from 'moment';
import { SharedModule } from './shared/shared.module';
import { MaterialModule } from './material/material.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatDialogModule } from '@angular/material/dialog';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgxPrintModule } from 'ngx-print';
import { DeviceDetectorService } from 'ngx-device-detector';

import {NgbModal, NgbModalModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { CreateTripComponent } from './admin/containers/maintainers/carpooling/create-trip/create-trip.component';
import { MapDialogComponent } from './admin/containers/maintainers/carpooling/map-dialog/map-dialog.component';

//import { GoogleMapsModule} from '@angular/google-maps';
import { ZXingScannerModule } from '@zxing/ngx-scanner';

//maps
import { AgmCoreModule } from '@agm/core';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { AgmDirectionModule } from 'agm-direction';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { CloseSeccionModalComponent } from './dialogModal/closeSeccion/close-seccion-modal/close-seccion-modal.component';
import { QRCodeModule } from 'angularx-qrcode';

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent,CloseSeccionModalComponent],
  imports: [
   
    QRCodeModule,
    BrowserModule,
    AppRoutingModule,
    ScrollingModule,
    MatDialogModule,
    NgxPrintModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgbModalModule,
    NgbModule,
    MatDialogModule,
    GooglePlaceModule,
    NgxMaterialTimepickerModule,
    SharedModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      // defaultLanguage: "es",
    }),

    //maps
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBevq0U9MsC_EG5vk6vwxWB-4w3MjIBqsY',
      libraries: ["places"]
    }),
    //MatGoogleMapsAutocompleteModule,
    AgmDirectionModule,
    AgmCoreModule.forRoot(),
  ],
  providers: [
    DeviceDetectorService
  ],
  entryComponents: [CloseSeccionModalComponent],
  bootstrap: [AppComponent],
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/translation/', '.json'); 
}
