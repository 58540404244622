import { Injectable, QueryList } from '@angular/core';
import { api } from './axios.config.service';

@Injectable({
  providedIn: 'root'
})
export class Access_userService {
  private travelUser: string = "accessUser"
  private token: string = localStorage.getItem('token')
  
constructor() { }


RegistrerEntry(body){
  return api.post(`/accessUser/`, body, {
      headers: {
          Authorization: "Bearer " + this.token,
      }
  })
  .then((res)=>res.data)
  .catch((err)=>{ 
      throw err.response
  })
}

}
