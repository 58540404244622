import { Injectable } from '@angular/core';
import { api } from './axios.config.service';
import { MatSnackBar } from '@angular/material';
@Injectable({
  providedIn: 'root',
})
export class UserPublicService {
  apiName: string = 'user';

  constructor(public snack: MatSnackBar) {}

  login = (body) =>
    api
      .post(`${this.apiName}/login`, body)
      .then((res: any) => {
        console.log({ res });
        //console.log("res service", { res })
        if (res.data) {
          //console.log("res service data", res.data )
          return res.data;
        } else {
          //console.log("res service error", res.response.data.message)
          console.log(res);
          this.createsnackbar(res.response.data.Results, '', 'danger');
        }
      })
      .catch((err) => {
        //console.log("error service", err)
        throw err;
      });

  loginout = (body) =>
    api
      .post(`${this.apiName}/loginOut`, body, {
        headers: { Authorization: 'Bearer ' + body.token },
      })
      .then((res: any) => {
        console.log({ res });

        if (res.data) {
          return res.data;
        } else {
          //console.log("res service error", res.response.data.message)
          console.log(res);
          this.createsnackbar(res.response.data.Results, '', 'danger');
        }
      })
      .catch((err) => {
        //console.log("error service", err)
        throw err;
      });


      resetPass = (id,body) =>
      api
        .put(`${this.apiName}/resetPass/pass/${id}`, body, {
       
          headers: { Authorization: 'Bearer ' + body.token },
        })
        .then((res: any) => {
          console.log({ res });
  
          if (res.data) {
            return res.data;
          } else {
            //console.log("res service error", res.response.data.message)
            console.log(res);
            this.createsnackbar(res.response.data.Results, '', 'danger');
          }
        })
        .catch((err) => {
          //console.log("error service", err)
          throw err;
        });

  getByAuth =()=>
    api
      .get(`${this.apiName}/get-user-by-auth`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
        },
      }).then((res) => {
        if (res.data) {
          //console.log("res service data", res.data )
          return res.data;
        } else {
          //console.log("res service error", res.response.data.message)
          console.log(res);
        }
      })
      .catch((err) => {
        throw err;
      });
  

  public createsnackbar(message, action, type) {
    const dialogRef = this.snack.open(message, action, {
      duration: 4000,
      panelClass: type,
      horizontalPosition: 'center',
    });
  }
}
