import axios from 'axios';
//import Auth from './../acl/auth';

import { environment } from 'src/environments/environment';

const api = axios.create({
  baseURL: `${environment.apiurl}/`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return error;
  }
);

export { api };
