import { NgModule, Component } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AccessTokenGuard } from "./guard/access-token.guard";
import { PageNotFoundComponent } from "./shared/containers/page-not-found/page-not-found.component";

const routes: Routes = [
  { path: "", redirectTo: "/", pathMatch: "full" },
  {
    path: "",
    loadChildren: "./public/public.module#PublicModule",
  },
  {
    path: "admin",
    loadChildren: "./admin/admin.module#AdminModule",
    canLoad : [ AccessTokenGuard]
  },
  {
    path: "**",
    component: PageNotFoundComponent,
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
