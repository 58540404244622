import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-close-seccion-modal',
  templateUrl: './close-seccion-modal.component.html',
  styleUrls: ['./close-seccion-modal.component.scss']
})
export class CloseSeccionModalComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<CloseSeccionModalComponent>) { }

  ngOnInit() {
  }
  onNoClick(): void {
   
    this.dialogRef.close();
  }
}
