import { UserService } from './admin/services/api/user.service';
import { Component, ViewChild, Input, Inject, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { UserPublicService } from './public/services/api/user.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
import { milliSeconds } from "../environments/environment";
import { Access_userService } from './admin/services/api/access_user.service';
import { MatDialog, MatSnackBar } from '@angular/material';

import { CloseSeccionModalComponent } from './dialogModal/closeSeccion/close-seccion-modal/close-seccion-modal.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'arabica-frontend';
  deviceInfo = null;
  entryBody: any = {
    user_id: "",
    module: "",
    uuidDevice: "",
    versionSO: "",
    model: "",
    cordova: "",
    manufacturer: "",
    isVirtual: "",
    serial: "",
    TypeAccess_id: "",
    platform: "",
    seconds: "",
    browser: "",
    V_browser: "",
    operatingSystem: "",
    isMobile: "",
    isDesktop: "",
  };
  param = { name: 'TEAM ADP' };
  @Input() nav;
  sideBarIsOpened = false;
  permissions = [];

  constructor(
    private translate: TranslateService,
    private permissionsService: NgxPermissionsService,
    private deviceService: DeviceDetectorService,
    private userService: UserPublicService,
    public snack: MatSnackBar,
    private router: Router,
    public dialog: MatDialog,
    private AccessUserService: Access_userService,

  ) {
    translate.setDefaultLang('en');
    translate.use('es');
    this.initEntry()
  }
  async ngOnInit() {

    localStorage.removeItem('isModal')
    this.sideBarIsOpened = false;
    if (localStorage.getItem('Permission')) {
      await this.permissionsService.loadPermissions(
        JSON.parse(localStorage.getItem('Permission'))
      );
      await this.userService.getByAuth().then(async (res: any) => {
        if (res.assignation) {
          const data = res.assignation;
          for (let index = 0; index < data.length; index++) {
            this.permissions.push(data[index].Permission.slug);
          }

          await this.permissionsService.loadPermissions(this.permissions);
          localStorage.setItem('Permission', JSON.stringify(this.permissions));
        }
      });


    } else {
      this.userService.getByAuth().then(async (res: any) => {
        if (res.assignation) {
          const data = res.assignation;
          for (let index = 0; index < data.length; index++) {
            this.permissions.push(data[index].Permission.slug);
          }

          await this.permissionsService.loadPermissions(this.permissions);
          localStorage.setItem('Permission', JSON.stringify(this.permissions));
        }
      });
    }
  }

  async initEntry() {
    let router = ""
    let typeAccess
    let cutRouter = []
    let activity = false
    let contador = 0


    document.addEventListener("mousemove", (mousemove) => {
      if (!mousemove) {
        activity = false
      } else {
        activity = true
      }
    })
    document.addEventListener("mousewheel", (mousewheel) => {
      if (!mousewheel) {
        activity = false
      } else {
        activity = true
      }
    })
    document.addEventListener("keypress", (keypress) => {
      if (!keypress) {
        activity = false
      } else {
        activity = true
      }
    })

    document.addEventListener("click", (click) => {
      if (!click) {
        activity = false
      } else {
        activity = true
      }
    })

    setInterval(() => {


      if (activity == false) {
        contador++
      }
      if (activity == true) {
        contador = 0
      }
    }, 1000)

    const set = await setInterval(async () => {
      if (localStorage.getItem('login') == 'true') {
        if (contador < 60) {
          activity = false
          router = this.router.url
          cutRouter = this.router.url.split('/')
          const id_user = localStorage.getItem('id')
          const isMobile = this.deviceService.isMobile();
          this.deviceInfo = this.deviceService.getDeviceInfo();
          const isDesktopDevice = this.deviceService.isDesktop();
          const isModal = JSON.parse(localStorage.getItem('isModal'))

          if (isModal) {
            router = ""
            if (isModal.openModal == true && isModal.component != null) { router = isModal.component, cutRouter = [] }
          }
          if (cutRouter[1] == "admin" && cutRouter[2] == "role" && cutRouter[3] == "edit") { router = "/admin/role/edit/" }
          if (cutRouter[1] == "admin" && cutRouter[2] == "permission" && cutRouter[3] == "edit") { router = "/admin/permission/edit/" }
          if (cutRouter[1] == "admin" && cutRouter[2] == "location" && cutRouter[3] == "edit") { router = "/admin/location/edit/" }
          if (cutRouter[1] == "admin" && cutRouter[2] == "building" && cutRouter[3] == "edit") { router = "/admin/building/edit/" }
          if (cutRouter[1] == "admin" && cutRouter[2] == "section" && cutRouter[3] == "edit") { router = "/admin/section/edit/" }
          if (cutRouter[1] == "admin" && cutRouter[2] == "accessTurnstile" && cutRouter[3] == "edit") { router = "/admin/accessTurnstile/edit/" }
          if (cutRouter[1] == "admin" && cutRouter[2] == "schedule" && cutRouter[3] == "details") { router = "/admin/schedule/details/" }
          if (cutRouter[1] == "admin" && cutRouter[2] == "createVehicle" && cutRouter[3] == "update") { router = "/admin/createVehicle/update/" }
          if (cutRouter[1] == "admin" && cutRouter[2] == "maps" && cutRouter[3] == "routes") { router = "/admin/maps/routes/" }
          if (cutRouter[1] == "admin" && cutRouter[2] == "user" && cutRouter[3] == "edit") { router = "/admin/user/edit/" }
          if (cutRouter[1] == "admin" && cutRouter[2] == "category" && cutRouter[3] == "edit") { router = "/admin/category/edit/" }
          if (cutRouter[1] == "admin" && cutRouter[2] == "element" && cutRouter[3] == "edit") { router = "/admin/element/edit/" }
          if (cutRouter[1] == "admin" && cutRouter[2] == "reservation" && cutRouter[3] == "details") { router = "/admin/reservation/details/" }

          switch (router) {
            case "/admin":
              typeAccess = 1
              break;
            case "/admin/role":
              typeAccess = 44
              break;
            case "/admin/role/create":
              typeAccess = 45
              break;
            case "/admin/role/edit/":
              typeAccess = 46
              break;
            case "/admin/permission":
              typeAccess = 47
              break;
            case "/admin/permission/edit/":
              typeAccess = 48
              break;
            case "/admin/location":
              typeAccess = 49
              break;
            case "/admin/location/create":
              typeAccess = 50
              break;
            case "/admin/location/edit/":
              typeAccess = 51
              break;
            case "/admin/building":
              typeAccess = 52
              break;
            case "/admin/building/create":
              typeAccess = 53
              break;
            case "/admin/building/edit/":
              typeAccess = 54
              break;
            case "/admin/section":
              typeAccess = 55
              break;
            case "/admin/section/create":
              typeAccess = 56
              break;
            case "/admin/section/edit/":
              typeAccess = 57
              break;
            case "/admin/accessTurnstile":
              typeAccess = 58
              break;
            case "/admin/accessTurnstile/create":
              typeAccess = 59
              break;
            case "/admin/accessTurnstile/edit/":
              typeAccess = 60
              break;
            case "/admin/delot":
              typeAccess = 61
              break;
            case "/admin/schedule":
              typeAccess = 62
              break;
            case "/admin/schedule/create":
              typeAccess = 63
              break;
            case "/admin/schedule/details/":
              typeAccess = 64
              break;
            case "/admin/carpooling":
              typeAccess = 22
              break;
            case "/admin/createVehicle":
              typeAccess = 23
              break;
            case "/admin/createVehicle/create":
              typeAccess = 24
              break;
            case "/admin/createTrip":
              typeAccess = 25
              break;
            case "/admin/avaliableTrips":
              typeAccess = 27 || 28
              break;
            case "/admin/maps/routes/":
              typeAccess = 29
              break;
            case "ModalDeleteVehicleComponent":
              typeAccess = 87
              break;
            case "/admin/createVehicle/update/":
              typeAccess = 88
              break;
            case "/admin/user":
              typeAccess = 65
              break;
            case "/admin/user/create":
              typeAccess = 66
              break;
            case "MyModalComponent:user":
              typeAccess = 67
              break;
            case "/admin/user/edit/":
              typeAccess = 68
              break;
            case "/admin/validate":
              typeAccess = 69
              break;
            case "ValidateModalComponent":
              typeAccess = 70
              break;
            case "/admin/visit":
              typeAccess = 2
              break;
            case "MyModalComponent:visit":
              typeAccess = 5
              break;
            case "/admin/visit/create":
              typeAccess = 5
              break;
            case "/admin/new":
              typeAccess = 33
              break;
            case "/admin/category":
              typeAccess = 73
              break;
            case "/admin/category/create":
              typeAccess = 74
              break;
            case "/admin/category/edit/":
              typeAccess = 75
              break;
            case "/admin/element":
              typeAccess = 76
              break;
            case "/admin/element/create":
              typeAccess = 77
              break;
            case "/admin/element/edit/":
              typeAccess = 78
              break;
            case "/admin/reservation":
              typeAccess = 7
              break;
            case "/admin/reservation/details/":
              typeAccess = 11
              break;
            case "/admin/office/reports":
              typeAccess = 83
              break;
            case "/admin/users/reports":
              typeAccess = 84
              break;
            case "/admin/visit/reports":
              typeAccess = 85
              break;
            case "/admin/reservations/reports":
              typeAccess = 86
              break;

            default:
              break;
          }

          this.entryBody = {
            user_id: +id_user,
            module: router,
            versionSO: this.deviceInfo.os_version,
            platform: "Web",
            manufacturer: this.deviceInfo.deviceType,
            model: this.deviceInfo.deviceType,
            browser: this.deviceInfo.browser,
            V_browser: this.deviceInfo.browser_version,
            operatingSystem: this.deviceInfo.os,
            isMobile: isMobile,
            isDesktop: isDesktopDevice,
            serial: null,
            isVirtual: false,
            cordova: null,
            uuidDevice: null,
            TypeAccess_id: typeAccess,
            seconds: milliSeconds
          }

          // this.openDialog()
          this.AccessUserService.RegistrerEntry(this.entryBody).then((res: any) => {


            //console.log({router});
          }).catch((err: any) => {

            console.log(err.data.close);

            if (err.data.close) {
              localStorage.clear();

              this.openDialog()


            }
          })

        }
      }
    }, milliSeconds)


  }
  openDialog() {
    const dialogRef = this.dialog.open(CloseSeccionModalComponent, {
      disableClose: true,
      width: '250px',
      data: {


      },
    });
    dialogRef.afterClosed().subscribe(result => {
      this.router.navigate(['/']);
      if (result) {

        console.log("Result is TRUE!");
      }
    });

  }

  public createsnackbar(Results, action, type) {
    const dialogRef = this.snack.open(Results, action, {
      duration: 4000,
      panelClass: type,
      horizontalPosition: 'center',
    });
  }

  toggleSideBar(shouldOpen: boolean) {
    this.sideBarIsOpened = !this.sideBarIsOpened;
  }
}

// export class AppComponent {
//   title = 'i18nDemo';
//   languageList = [    { code: 'en', label: 'English' },    { code: 'hi', label: 'हिंदी' },    { code: 'es', label: 'Espanol' }  ];

//   constructor(@Inject(LOCALE_ID) protected localeId: string) { }}
